<template>
	<a target="_blank" rel="noopener noreferrer" :href="getUrl({ ...props })">
		<slot />
	</a>
</template>

<script setup>
import BookingEngine from '@becurious/cubilis';
const { locale } = useI18n();
const bookingEngine = useState('bookingEngine', () => {});

const props = defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	hotelid: { type: String, default: '' },
	packageid: { type: String, default: '' },
	roomid: { type: String, default: '' },
});

const getUrl = (options) => {
	if (!bookingEngine.value) {
		return null;
	}

	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	return bookingEngine.value.getUrl(options);
};

onMounted(() => {
	bookingEngine.value = new BookingEngine({
		hotelID: props.hotelid,
	});
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&.fixedmobile {
		display: none;
		width: 100%;
		position: fixed;
		bottom: 0;
		margin: 0;
		padding: 20px 0;
		z-index: 100;

		svg {
			margin-right: 5px;
		}
	}

	&:hover {
		text-decoration: none;
	}
}
</style>
